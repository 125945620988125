@import "../../../style/breakpoints.scss";

.icz_selectDropdown {
    .css-1glikz8-control {
        @include xs {
            width: 100% !important;
        }
    }
}

.icz_sectionDropdown{
    @include xs {
        margin-top: 20px;
        width: 100%;
    }
}
