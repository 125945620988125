@import "./breakpoints.scss";

.swiper-slide .card{
    height: auto;
}

.swiper-pagination-bullet-active{
    color: #3cd17f !important;
}

.position-relative{
    position: relative !important;
}

.table-bordered tr,
.table-bordered td {
    border-left: 0;
    border-right: 0;

    &:first-child {
        td {
            border-top: none;
        }
    }

    &:last-child {
        td {
            border-bottom: none;
        }
    }
}

.card {
    .table.table-bordered {
        tr {
            td {
                &:first-child {
                    padding-left: 32px;
                }
            }

            th {
                &:first-child {
                    padding-left: 32px;
                }
            }
        }
    }
}



.icz_resp_flex_column {
    @include xs {
        display: flex;
        flex-direction: column;
        justify-content: flex-start !important;
        align-items: flex-start !important;
        width: 100%;

        .icz_tableContainer,
        .icz_chartContainer {
            width: 100%;
        }
    }
}

.card .table th,
.card .table td {
    vertical-align: middle !important;
}

// TO HIGHLIGHT THE ACTIVE TAB ONCLICK
.swiper-slide {
    .card {
        border: 2px solid transparent;
    }
}
.swiper-slide.active {
    .card {
        border: 2px solid #3cd17f;
    }
}

// .table.table-bordered tbody tr td:first-child,
// .card.table.table-bordered tbody tr th:first-child{
//     padding-left: 32px;
// }


.btn.btn-tertiary.active{
    color: #3cd17f;
    background: rgba(60, 209, 127, 0.1);
}

.tab_navigation.custom_tab{
    flex-wrap: nowrap !important;
    overflow-x: auto !important;
}