@import "../../../style/breakpoints.scss";

.page-header {
    @include xs {
        display: flex;
        flex-direction: column;
    }

    .icz_pageHeaderFiltersSection {
        .icz_pageHeaderFiltersWrapper {
            @include xs {
                display: flex;
                flex-direction: column;
            }
        }
    }
}

.icz_datePickerContainer {
    position: relative;
    height: 40px;
    .icz_datePickerSection {
        position: relative;
        height: 40px;
        min-width: 240px;
        width: 100%;
        background-color: #fff;
        border: 1px solid #eaeaea;
        border-radius: 7px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        // padding: 0 15px;
        &:hover {
            border-color: #acacac;
        }
        &:focus {
            border-color: #acacac;
        }
        .icz_datePickerWrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            font-size: 16px;
            color: #acacac;
            width: 100%;
            .icz_date {
            }
            .icz_fromDate {
                color: #acacac;
            }
            .icz_toDate {
                color: #acacac;
            }

            .icz_datePickerCalendar {
                fill: #acacac;
            }
        }
    }
}

.css-g1d714-ValueContainer{
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
}